import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {GET_ALL_LCW_USERS, GET_LCW_FORM_PDF_URL, UPDATE_LCW_USER_REVIEW_STATE} from "../common/constants/routes";


export const getAllLCWUsers = () => {
    return clientGetWrapper(GET_ALL_LCW_USERS);
}


export const updateLCWUserReviewState = (payload) => {
    return clientPostWrapper(UPDATE_LCW_USER_REVIEW_STATE,payload);
}

export const getLCWKvkkFormUrl = () => {
    return clientPostWrapper(GET_LCW_FORM_PDF_URL);

}



