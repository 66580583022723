import React, {useEffect, useState} from 'react';
import styles from "./ExportDropdown.module.scss";
import {Autocomplete, TextField} from "@mui/material";
import {
    EXPORT_FILE_TYPES,
} from "../../features/AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import {t} from "i18next";
import {DownloadOutlined} from "@mui/icons-material";


const ExportDropdown = ({handleCb}) => {

    const [fileType, setFileType] = useState(null);

    useEffect(() => {
        handleCb(fileType);
    }, [fileType]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaidFileTypeDropdownChange = (e) => {
        const {textContent} = e.target;
        setFileType(textContent);
    }

    return (
        <div className={styles.container}>
            <DownloadOutlined/>
            <Autocomplete
                name="type"
                freeSolo
                required
                sx={{width: 250}}
                value={fileType}
                size={"small"}
                options={Object.values(EXPORT_FILE_TYPES)}
                onChange={handlePaidFileTypeDropdownChange}
                onCallback={handleCb}
                renderInput={(params) => <TextField {...params}
                                                    label={t('common-filetypes.choose-type-to-export')}/>}/>
        </div>
    );
};

export default ExportDropdown;
