import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import styles from "./UserPayments.module.scss";
import {Alert, Autocomplete, Button, Chip, Input, TextField, Typography} from "@mui/material";
import {t} from "i18next";
import {
    deleteUserPaymentRequest,
    getAllPayments,
    getInvoiceSignalUrl,
    sendExcelFileForInsert,
    sendExcelFileForUpdate,
    updateUserPaymentData
} from "../../services/paymentsService";
import GenericTable from "../../common/table/GenericTable";
import {formatTurkishLira} from "../../common/utils";
import {AttachmentOutlined, RefreshOutlined, UploadFile, UploadFileOutlined} from "@mui/icons-material";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import {DOWNLOAD_ALL_PAYMENTS_EXCEL_DATA} from "../../common/constants/routes";
import dayjs from "dayjs";
import Modal from "../../common/modal/Modal";
import useModal from "../../common/hooks/useModal";
import ExportDropdown from "../../common/exportDropdown/ExportDropdown";
import {EXPORT_FILE_TYPES} from "../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";


const UserPayments = () => {

    const [paymentsData, setPaymentsData] = useState([]);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const [firstFormData, setFirstFormData] = useState(null);
    const [secondFormData, setSecondFormData] = useState(null);
    const [paymentIdWillDelete, setPaymentIdWillDelete] = useState(null);
    const {isModalOpen, toggleModal, modalContent} = useModal({
        title: "Delete Payment",
        content: "Are you sure you want to delete this payment?"
    });
    const handleGetAllPayments = async () => {
        setPaymentsData([]);
        const response = await getAllPayments();
        if (!!response.data && !!response.data.payments) {
            setPaymentsData(response.data.payments);
        }
    }
    useEffect(() => {
        handleGetAllPayments();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const paymentsColumnsData = {};
    paymentsColumnsData.visibility = {}
    paymentsColumnsData.data = [
        {
            accessorKey: 'hasoffersid',
            header: "User Id",
            enableClickToCopy: true
        },
        {
            accessorKey: 'id',
            header: "Payment Id",
            enableClickToCopy: true
        },
        {
            accessorKey: 'invoice_date',
            header: "Invoice Date",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.invoice_date, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        },
        {
            accessorKey: 'firstname',
            header: "Name",
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{cell.row.original.firstname + " " + cell.row.original.lastname}</div>
            )
        },
        {
            accessorKey: 'email',
            header: "Email",
            enableClickToCopy: true
        },
        {
            accessorKey: 'amount',
            header: t('common.amount'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.amount)}</div>
            )
        },
        {
            accessorKey: 'approval_status',
            header: t('common.approved_status'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>
                    <Autocomplete name="Approved Status"
                                  freeSolo
                                  sx={{
                                      width: 200
                                  }}
                                  options={["Approved", "Pending", "Declined"]}
                                  renderInput={(params) => <TextField {...params} label={t('common.approved_status')}/>}
                                  onChange={(e) => {
                                      console.log(e.target.textContent);
                                      cell.row.original.approval_status = e.target.textContent;
                                  }}
                                  defaultValue={cell.row.original.approval_status}>

                    </Autocomplete>
                </div>
            )
        },
        {
            accessorKey: 'payment_status',
            header: t('common.paid_status'),
            enableClickToCopy: true,
            size: 200,
            Cell: (cell) => (
                <div>
                    <Autocomplete name="Payment Status"
                                  freeSolo
                                  sx={{
                                      width: 200
                                  }}
                                  options={["Paid", "Not Paid"]}
                                  renderInput={(params) => <TextField {...params} label={t('common.paid_status')}/>}
                                  onChange={(e) => {
                                      cell.row.original.payment_status = e.target.textContent;
                                  }}
                                  defaultValue={cell.row.original.payment_status}>

                    </Autocomplete>
                </div>
            )
        },
        {
            accessorKey: 'note',
            header: t('common.user_info'),
            enableClickToCopy: true,
        },
        {
            accessorKey: 'invoice_url',
            header: t('common.attachments'),
            Cell: (cell) => (
                <div>
                    <Button onClick={() => openGCSFile(cell.row.original.invoice_url)} variant="contained"
                            color="success"
                            size="small">
                        <AttachmentOutlined/>
                    </Button>
                </div>
            )
        },
        {
            accessorKey: 'categories',
            header: 'Categories',
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>
                    {!!cell.row.original.categories &&
                        <div className={styles.tagsBlock}>
                            <div className={styles.tagsHolder}>
                                {!!cell.row.original.categories && JSON.parse(cell.row.original.categories).map(category => (
                                    <div key={category}><Chip color="primary" sx={{fontWeight: 700}}
                                                              label={category}></Chip></div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            )
        },
        {
            accessorKey: 'Actions',
            header: t('common.actions'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div className={styles.buttonHolder}>
                    <Button
                        variant="contained"
                        color="info"
                        size="big"
                        onClick={() => {
                            updateUserPayment(cell.row.original);
                        }}>UPDATE</Button>
                    <Button
                        variant="contained"
                        color="error"
                        size="big"
                        onClick={() => {
                            deleteUserPayment(cell.row.original);
                        }}>DELETE</Button>
                </div>
            )
        },
    ];

    const openGCSFile = async (url) => {
        const response = await getInvoiceSignalUrl(url);
        if (!!response.data) {
            window.open(response.data.url, '_blank');
        }
    }

    const deleteUserPayment = async (rowData) => {
        setPaymentIdWillDelete(rowData.id);
        toggleModal();
    }

    const updateUserPayment = async (rowData) => {
        console.log(rowData);
        const response = await updateUserPaymentData(rowData);
        if (!!response.data) {
            setToastContent("Data updated successfully");
        } else {
            setToastContent("Data update failed");
        }
        toggleToast();
    }

    const fileChangedNewRecords = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setFirstFormData(formData);
    }

    const sendCreateNewRecordsExcel = async () => {
        if (!firstFormData) {
            setToastContent("Please select a file first");
            toggleToast();
            return;
        }
        const response = await sendExcelFileForInsert(firstFormData);
        if (!!response.data && !!response.data.isAdded) {
            setToastContent("Data updated successfully");
            handleGetAllPayments();
        } else {
            setToastContent("Data update failed");
        }
        setFirstFormData(null);
        toggleToast();
    }
    const fileChangedUpdateRecords = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setSecondFormData(formData);
    }

    const sendUpdateRecordsExcel = async () => {
        if (!secondFormData) {
            setToastContent("Please select a file first");
            toggleToast();
            return;
        }
        const response = await sendExcelFileForUpdate(secondFormData);
        if (!!response.data && !!response.data.isAdded) {
            setToastContent("Data updated successfully");
            handleGetAllPayments();
        } else {
            setToastContent("Data update failed");
        }
        setSecondFormData(null);
        toggleToast();
    }

    const makeDeleteUserPaymentRequest = async () => {
        if(!paymentIdWillDelete) return;
        const response = await deleteUserPaymentRequest({id: paymentIdWillDelete});
        if(!!response.data && !!response.data.isDeleted) {
            setToastContent("Payment deleted successfully");
            handleGetAllPayments();
            toggleModal();
        }else{
            setToastContent("Something went wrong");
        }
    }


    const handleDownloadFile = async (type) => {
        switch (type) {
            case EXPORT_FILE_TYPES.csv:
                window.open((process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_ALL_PAYMENTS_EXCEL_DATA + "?type=csv", "_blank");
                break;
            case EXPORT_FILE_TYPES.excel:
                window.open((process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_ALL_PAYMENTS_EXCEL_DATA + "?type=excel", "_blank");
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title={"User Payments"}>
                <div className={styles.resultTable}>
                    <div className={styles.uploadHandler}>
                        <div className={styles.firstExcel}>
                            <Typography variant="h6">Create New Records</Typography>
                            <Input type="file" startIcon={<UploadFile/>} onChange={fileChangedNewRecords}/>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={sendCreateNewRecordsExcel}
                                disabled={!firstFormData}
                                startIcon={<UploadFileOutlined/>}>Upload</Button>

                        </div>
                        <div className={styles.firstExcel}>
                            <Typography variant="h6">Update Records</Typography>
                            <Input type="file" startIcon={<UploadFile/>} onChange={fileChangedUpdateRecords}/>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={sendUpdateRecordsExcel}
                                disabled={!secondFormData}
                                startIcon={<UploadFileOutlined/>}>Upload</Button>

                        </div>

                    </div>
                    <Button onClick={handleGetAllPayments}
                            variant="outlined"
                            sx={{mt: 2}}
                            startIcon={<RefreshOutlined/>}
                            color="info">Refresh Results</Button>
                    {paymentsData.length > 0 &&
                        <div>

                           <div className={styles.buttonHolder}>
                                <ExportDropdown handleCb={handleDownloadFile}/>
                            </div>
                            <GenericTable
                                outputColumns={paymentsColumnsData}
                                tableData={paymentsData}
                            />
                        </div>}
                </div>
                <div>
                    {!!paymentsData && paymentsData.length === 0 &&
                        <Alert sx={{mt: 2}} severity="info">No payment history</Alert>
                    }
                </div>
            </Card>
            <Modal
                isOpen={isModalOpen}
                onCancelation={toggleModal}
                onConfirmation={makeDeleteUserPaymentRequest}
                title={modalContent.title}
                content={modalContent.content}
            />
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default UserPayments;
