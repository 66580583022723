import React, {useEffect, useState} from 'react';
import styles from "./NavbarItems.module.scss";
import ListItem from "@mui/material/ListItem";
import {NavLink, useLocation} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useTranslation} from "react-i18next";
import {NAVBAR_ITEM_TOOLTIP, NAVIGATION_SIDEMENU_ITEMS_TEST_ID} from "../../../utils/testUtils";
import {navbarItems} from "../../../common/utils";
import {Avatar, Tooltip} from "@mui/material";

import {
    ADMIN_PAID_PROJECTS,
    ADVERTISER_MANAGEMENT,
    AFFILIATE_PERFORMANCE,
    AI_CHAT_ASSISTANT,
    AI_INFLUENCER_REVENUES,
    CREATE_CAMPAIGN,
    CREATE_PAID_PROJECT,
    CREATE_PROMO_CODE,
    INSERT_INFLUENCERS,
    LCW_NEW_PARTNERS,
    LOGOUT,
    PURCHASE_REPORTS,
    SIGN_UP,
    STATUS_CENTER,
    USER_PAYMENTS,
    USERS_ADMIN
} from "../../../common/constants/routes";
import useLogout from "../../../common/hooks/useLogout";
import {useSelector} from "react-redux";
import {
    AccessibilityNewOutlined,
    AddCircleOutlined,
    AttachMoneyOutlined,
    BrushOutlined,
    DiscountOutlined,
    GamesOutlined,
    HandshakeOutlined,
    ManageAccountsOutlined,
    NotificationsActiveOutlined,
    PaidOutlined,
    PaymentsOutlined,
    QueryStatsOutlined,
    RadarOutlined
} from "@mui/icons-material";
import lcwLogo from "../../../images/lcw.svg"
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const NavBarItems = ({open, setOpen}) => {
    const [openNestedMenu, setOpenNestedMenu] = React.useState(false);

    const handleNestedMenuClick = () => {
        setOpen(true)
        setOpenNestedMenu(!openNestedMenu);
    };

    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {logout} = useLogout();
    const [currentNavbarItems, setCurrentNavbarItems] = useState(null);
    const isMasterPassword = useSelector(state => state.auth?.user?.isMasterPassword);
    const user = useSelector(state => state.auth?.user.user);
    useEffect(() => {
        if (!!isMasterPassword) {
            const newUserExists = navbarItems.find(item => item.path === SIGN_UP);
            if (!newUserExists) {
                navbarItems.splice(2, 0, {
                    name: 'menu-item.create-new-user',
                    path: SIGN_UP,
                    icon: <AddCircleOutlined/>
                })
            }

            const insertInfluencers = navbarItems.find(item => item.path === INSERT_INFLUENCERS);
            if(!insertInfluencers){
                navbarItems.splice(3, 0, {
                    name: 'menu-item.insert-influencers',
                    path: INSERT_INFLUENCERS,
                    icon: <AccessibilityNewOutlined/>
            })}

            const aiInfluencerRevenues= navbarItems.find(item => item.path === AI_INFLUENCER_REVENUES);
            if(!aiInfluencerRevenues){
                navbarItems.splice(4, 0, {
                    name: 'menu-item.ai-influencer-revenues',
                    path: AI_INFLUENCER_REVENUES,
                    icon: <AttachMoneyOutlined/>
            })}


            const aiRadar = navbarItems.find(item => item.path === AI_CHAT_ASSISTANT);
            if (!aiRadar) {
                navbarItems.splice(4, 0, {
                    name: 'menu-item.ai-radar',
                    path: AI_CHAT_ASSISTANT,
                    icon: <RadarOutlined/>
                })
            }

            const paidProjects = navbarItems.find(item => item.path === ADMIN_PAID_PROJECTS);
            if (!paidProjects) {
                navbarItems.splice(2, 0, {
                    name: 'paid-projects.main-title',
                    path: ADMIN_PAID_PROJECTS,
                    icon: <PaidOutlined/>
                })
            }
            const affiliatePerformanceExist = navbarItems.find(item => item.path === AFFILIATE_PERFORMANCE);
            if (!affiliatePerformanceExist) {
                navbarItems.splice(2, 0, {
                    name: 'menu-item.affiliates-performances',
                    path: AFFILIATE_PERFORMANCE,
                    icon: <HandshakeOutlined/>
                })
            }

            const userPayments = navbarItems.find(item => item.path === USER_PAYMENTS);
            if (!userPayments) {
                navbarItems.splice(2, 0, {
                    name: 'menu-item.user-payments',
                    path: USER_PAYMENTS,
                    icon: <PaymentsOutlined/>
                })
            }

            const usersAdminExist = navbarItems.find(item => item.path === USERS_ADMIN);
            if (!usersAdminExist) {
                navbarItems.splice(2, 0, {
                    name: 'user-admin.title-users',
                    path: USERS_ADMIN,
                    icon: <ManageAccountsOutlined/>
                })
            }

        }
        if (user.offer_id_list.includes("132") || isMasterPassword) {
            const lcwPartnersExist = navbarItems.find(item => item.path === LCW_NEW_PARTNERS);
            if (!lcwPartnersExist) {
                navbarItems.splice(isMasterPassword ? 4 : 2, 0, {
                    name: 'menu-item.lcw-new-partners',
                    path: LCW_NEW_PARTNERS,
                    icon: <Avatar src={lcwLogo} alt={lcwLogo}/>
                })
            }
        }
        if (!user.offer_id_list.includes("132")) {
            const lcwPartnersExist = navbarItems.find(item => item.path === PURCHASE_REPORTS);
            if (!lcwPartnersExist) {
                navbarItems.splice(isMasterPassword ? 4 : 1, 0, {
                    name: 'menu-item.purchase_reports',
                    path: PURCHASE_REPORTS,
                    icon: <QueryStatsOutlined/>
                })
            }
        }

        const isFbReviewUser = user.offer_id_list.includes("13");
        if (!isFbReviewUser) {
            const advManagerExist = navbarItems.find(item => item.path.includes(ADVERTISER_MANAGEMENT));
            if (!advManagerExist) {
                navbarItems.splice(2, 0, {
                    name: 'menu-item.adv_management',
                    path: ADVERTISER_MANAGEMENT,
                    icon: <GamesOutlined/>,
                    subItems: [
                        {
                            name: 'menu-item.create-campaign',
                            path: CREATE_CAMPAIGN,
                        },
                        {
                            name: 'menu-item.create-promo-code',
                            path: CREATE_PROMO_CODE,
                        },
                        {
                            name: 'menu-item.create-paid-project',
                            path: CREATE_PAID_PROJECT,
                        },
                        {
                            name: 'menu-item.status-center',
                            path: STATUS_CENTER,
                        },
                    ]
                })
            }
        }else{
            const aiRadar = navbarItems.find(item => item.path === AI_CHAT_ASSISTANT);
            if (!aiRadar) {
                navbarItems.splice(1, 0, {
                    name: 'menu-item.ai-radar',
                    path: AI_CHAT_ASSISTANT,
                    icon: <RadarOutlined/>
                })
            }
            const usersAdminExist = navbarItems.find(item => item.path === USERS_ADMIN);
            if (!usersAdminExist) {
                navbarItems.splice(2, 0, {
                    name: 'user-admin.title-users',
                    path: USERS_ADMIN,
                    icon: <ManageAccountsOutlined/>
                })
            }
        }
        setCurrentNavbarItems(navbarItems);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <List data-testid={NAVIGATION_SIDEMENU_ITEMS_TEST_ID}>
            {!!currentNavbarItems && currentNavbarItems.map((item) => (
                <Tooltip
                    data-testid={NAVBAR_ITEM_TOOLTIP}
                    title={open ? null : t(item.name)}
                    arrow
                    placement="right"
                    key={item.name}
                >
                    <ListItem
                        key={item.name}
                        disablePadding
                        sx={{display: 'block', mt: 2}}
                    >
                        {(!item.subItems || item.subItems.length === 0) &&
                            <div
                                className={pathname.includes(item.path) ? styles.selectedItem : styles.notSelectedItem}>
                                {<NavLink
                                    key={item.name}
                                    data-testid={item.name}
                                    onClick={() => {
                                        if (item.path === LOGOUT)
                                            logout()
                                    }}
                                    to={item.path === LOGOUT ? false : item.path}
                                >
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : '0',
                                                justifyContent: 'center',
                                            }}
                                            className={styles.icon}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            className={styles.itemText}
                                            primary={t(item.name)}
                                            sx={{display: open ? "block" : "none"}}
                                        />
                                    </ListItemButton>
                                </NavLink>}
                            </div>
                        }
                        {(!!item.subItems && item.subItems.length > 0) &&
                            <div>
                                <ListItemButton onClick={handleNestedMenuClick}>
                                    <ListItemIcon sx={{marginLeft: open ? 0.6 : 1.5}}>
                                        <GamesOutlined/>
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        m: -2,
                                        display: open ? "block" : "none"
                                    }}
                                                  primary={t('menu-item.adv_management')}/>
                                    {openNestedMenu ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>
                                <Collapse sx={{display: open ? "block" : "none"}} in={openNestedMenu} timeout="auto"
                                          unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.subItems.map((subItem) => (
                                            <div
                                                className={pathname.includes(subItem.path) ? styles.selectedItem : styles.notSelectedItem}
                                                key={subItem.name}>
                                                <NavLink
                                                    key={subItem.name}
                                                    data-testid={subItem.name}
                                                    onClick={() => {
                                                        if (item.path === LOGOUT) logout()
                                                    }}
                                                    to={item.path === LOGOUT ? false : `${item.path}${subItem.path}`}
                                                >
                                                    <ListItemButton sx={{pl: 4}}>
                                                        <ListItemIcon className={styles.icon}>
                                                            {subItem.path === CREATE_CAMPAIGN && <BrushOutlined/>}
                                                            {subItem.path === CREATE_PROMO_CODE && <DiscountOutlined/>}
                                                            {subItem.path === CREATE_PAID_PROJECT && <PaidOutlined/>}
                                                            {subItem.path === STATUS_CENTER &&
                                                                <NotificationsActiveOutlined/>}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            className={styles.itemText}
                                                            primary={t(subItem.name)}
                                                            sx={{
                                                                color: "#202020",
                                                                marginLeft: -2,
                                                                fontSize: 11
                                                            }}/>
                                                    </ListItemButton>
                                                </NavLink>
                                            </div>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        }
                    </ListItem>

                </Tooltip>
            ))}
        </List>
    );
}

export default NavBarItems;