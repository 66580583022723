import React, {useEffect, useState} from 'react';
import GenericTable from "../../../../common/table/GenericTable";
import styles from "./ExistPaidProjectInfluencers.module.scss";
import {approveOrRejectInfluencers, getSelectedProjectInfluencers} from "../../../../services/paidProjectsService";
import dayjs from "dayjs";
import {Alert, Button, Checkbox} from "@mui/material";
import EditInfluencer from "../../EditInfluencer/EditInfluencer";
import {formatTurkishLira} from "../../../../common/utils";
import {CheckCircleOutlineOutlined, DoNotDisturbOutlined} from "@mui/icons-material";
import {DOWNLOAD_PAID_PROJECT_INFLUENCERS} from "../../../../common/constants/routes";
import {t} from "i18next";
import Modal from "../../../../common/modal/Modal";
import useModal from "../../../../common/hooks/useModal";
import Toast from "../../../../common/toast/Toast";
import useToast from "../../../../common/hooks/useToast";
import ExportDropdown from "../../../../common/exportDropdown/ExportDropdown";
import {EXPORT_FILE_TYPES} from "../../../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";

const ExistPaidProjectInfluencers = ({projectId, setParentInfluencers}) => {
    const [influencers, setInfluencers] = useState(null);
    const influencersColumns = {};
    const [selectedInfluencers, setSelectedInfluencers] = useState(new Map());
    const {isModalOpen, toggleModal, modalContent, setModalContent} = useModal();
    const [isApproveAll, setIsApproveAll] = useState(false);
    const [isRejectAll, setIsRejectAll] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    influencersColumns.visibility = {}
    influencersColumns.data = [
        {
            accessorKey: 'affiliate_id',
            header: "Influencer ID",
            Cell: (cell) => (
                <div className={styles.checkBoxHolder}>
                    <Checkbox onChange={e => addRemoveInfluencer(cell.row.original.affiliate_id)}/>
                    <div>{cell.row.original.affiliate_id}</div>
                </div>
            )
        },
        {
            accessorKey: 'firstname',
            header: "Influencer Name",
            Cell: (cell) => (
                <div>{cell.row.original.firstname} {cell.row.original.lastname}</div>
            )
        },
        {
            accessorKey: 'website',
            header: "Social Account",
        },
        {
            accessorKey: 'amount',
            header: "Project Budget",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.amount ? cell.row.original.amount : 0)}</div>
            )
        },
        {
            accessorKey: 'status',
            header: "Status",
        },
        {
            accessorKey: 'info',
            header: "Note",
        },
        {
            accessorKey: 'created_at',
            header: "Created At",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.created_at, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        }
    ];


    const addRemoveInfluencer = (affiliateId) => {
        if (selectedInfluencers.has(affiliateId)) {
            selectedInfluencers.delete(affiliateId);
        } else {
            selectedInfluencers.set(affiliateId, true);
        }
        setSelectedInfluencers(new Map(selectedInfluencers));
    }

    const getExistPaidProjectInfluencers = async (projectId) => {
        setIsUpdating(true);
        const response = await getSelectedProjectInfluencers(projectId);
        if (!response.data || response.data.length === 0) return;
        setIsUpdating(false);
        setInfluencers(response.data);
        setParentInfluencers(response.data);

    }

    useEffect(() => {
        if (!projectId) return;
        getExistPaidProjectInfluencers(projectId).then(e => e);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const expandInfluencer = (cell) => {
        return (<EditInfluencer infData={cell.row.original}/>)
    }


    const approveOrRejectAll = async (isApproved) => {
        setIsApproveAll(isApproved);
        setIsRejectAll(!isApproved);
        const selectedInfluencersArray = Array.from(selectedInfluencers.keys());
        let content = isApproved ? t("paid-project-influencer.approve-all-msg") : t("paid-project-influencer.reject-all-msg");

        content += selectedInfluencersArray.map(affId => {
            return `${affId}`
        }).join("-");
        setModalContent({
            title: isApproved ? t("paid-project-influencer.approve-all") : t("paid-project-influencer.reject-all"),
            content: content
        })
        toggleModal();
    }
    const confirmedToApproveOrReject = async () => {
        const finalApproveOrReject = isApproveAll ? true : isRejectAll ? false : null;
        const payload = {
            projectId: projectId,
            influencerIds: Array.from(selectedInfluencers.keys()),
            isApproved: finalApproveOrReject
        }
        const response = await approveOrRejectInfluencers(payload);
        if (!!response && response.data) {
            toggleModal();
            getExistPaidProjectInfluencers(projectId).then(e => e);
            setSelectedInfluencers(new Map());
            setToastContent("Influencers are updated successfully");
        } else {
            setToastContent("An error occurred while updating influencers");
        }
        toggleToast();
    }

    const handleDownloadExportFile = async (type) => {
        switch (type) {
            case EXPORT_FILE_TYPES.excel:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_PAID_PROJECT_INFLUENCERS}?pid=${projectId}&type=excel`, "_blank");
                break;
            case EXPORT_FILE_TYPES.csv:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_PAID_PROJECT_INFLUENCERS}?pid=${projectId}&type=csv`, "_blank");
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Exist Influencers</div>
            {!!influencers && influencers.length > 0 &&
                <div>
                    <div className={styles.buttonHolder}>
                        <ExportDropdown handleCb={handleDownloadExportFile}/>
                        <Button onClick={e => approveOrRejectAll(true)}
                                startIcon={<CheckCircleOutlineOutlined/>}
                                variant="contained"
                                disabled={selectedInfluencers.size === 0}
                                color="success">{t("paid-project-influencer.approve-all")}</Button>
                        <Button onClick={e => approveOrRejectAll(false)}
                                startIcon={<DoNotDisturbOutlined/>}
                                variant="contained"
                                disabled={selectedInfluencers.size === 0}
                                color="error">{t("paid-project-influencer.reject-all")}</Button>
                    </div>
                    {!isUpdating &&
                        <GenericTable
                            outputColumns={influencersColumns}
                            tableData={influencers}
                            rowCount={influencers.length}
                            allRowsAreExpanded={influencers.length === 1}
                            expand={expandInfluencer}
                        />}
                    <Modal
                        isOpen={isModalOpen}
                        onCancelation={toggleModal}
                        title={modalContent.title}
                        content={modalContent.content}
                        onConfirmation={confirmedToApproveOrReject}
                    />
                    <Toast
                        isOpen={isToastOpen}
                        onClose={toggleToast}
                        content={toastContent}
                        severity="info"
                    />

                </div>
            }
            {(!influencers || influencers.length === 0) && <Alert severity="info">No influencers found</Alert>}
        </div>
    );
};

export default ExistPaidProjectInfluencers;
