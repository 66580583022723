import React, {useEffect} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import {getInfluencerRevenues} from "../../services/influencerChatsService";
import GenericTable from "../../common/table/GenericTable";
import Card from "../../common/card/Card";
import {formatTurkishLira} from "../../common/utils";

const AIInfluencerRevenues = () => {

    const [revenues, setRevenues] = React.useState([]);

    const conversionColumns = {};
    conversionColumns.visibility = {}
    conversionColumns.data = [
        {
            accessorKey: 'ad_id',
            header: "Ad Id",
        },
        {
          accessorKey: 'offer_name',
            header: "Offer Name",
        },
        {
            accessorKey: 'device_brand',
            header: "Device Brand",
        },
        {
            accessorKey: 'date',
            header: "Date",
            Cell: (cell) => (
                <div>{new Date(cell.row.original.date.value).toDateString()}-{new Date(cell.row.original.date.value).toLocaleTimeString()}</div>
            )
        },
        {
            accessorKey: 'net_sale_amount',
            header: "Sale Amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.net_sale_amount)}</div>
            )
        },
        {
            accessorKey: 'adv_sub5',
            header: "Postback Message",
        }
    ]

    const revenueColumns = {};
    revenueColumns.visibility = {}
    revenueColumns.data = [
        {
            accessorKey: 'affiliate_id',
            header: "Affiliate Id",
        },
        {
            accessorKey: 'social',
            header: "Social Account",
        },
        {
            accessorKey: 'saleAmount',
            header: "Sale Amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.saleAmount)}</div>
            )
        }
    ];

    const getAIInfluencerRevenueResults = async () => {
        const response = await getInfluencerRevenues();
        if (!!response) {
            setRevenues(response.data);
        }
    }

    const expandConversions = (cell) => {
        return (
            <div>
                <GenericTable
                    tableData={cell.row.original.conversions}
                    outputColumns={conversionColumns}
                />
            </div>
        )
    }

    useEffect(() => {
        getAIInfluencerRevenueResults()
    }, []); //

    return (
        <div>
            <BreadcrumbsBar/>
            <Card title="AI Influencer Revenues">
                <GenericTable
                    tableData={revenues}
                    outputColumns={revenueColumns}
                expand={expandConversions}/>
            </Card>
        </div>
    );
};

export default AIInfluencerRevenues;
